import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Link from '../components/Common/Link'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const DigitalSurveyingConstruction: React.FC = () => {
  return (
    <>
      <SEO
        title="Digital Surveying for Construction"
        description="This article by Andrew Serafimin discusses the approach to conducting geodetic surveys on the premises of operating industrial enterprises using modern instruments and technologies."
      />

      <NewsSectionBlock date="03.10.2023">
        <Typography variant="h1" color="blue">
          Digital Surveying for Construction
        </Typography>
        <Typography variant="body1">
          The construction and reconstruction of industrial enterprises have
          become complex and resource-intensive processes, requiring highly
          accurate initial data and a substantial volume of project information.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/news/digital-surveying-construction/image-1.jpg"
            alt="Мобильная версия админки в SIGNAL HUB"
            title="Мобильная версия админки в SIGNAL HUB"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          The efficiency and success of production directly depend on the
          quality of design, and design, in turn, depends on the quality and
          comprehensiveness of surveying works. The need to transition to
          digital methods of surveys, design, and construction using information
          models is evident.
        </Typography>
        <Typography variant="body1">
          Since the majority of construction sites are located on the premises
          of existing industrial facilities, characterized by a vast number of
          production buildings, transit communications, and engineering
          structures, there is a necessity to carry out work not only concerning
          planned construction projects but also in alignment with existing
          facilities and technological processes at these enterprises.
        </Typography>
        <Typography variant="body1">
          Andrew Serafimin, the head of the Digital Survey department at SIGNAX,
          wrote an exclusive article for the leading global portal{' '}
          <Link
            href="https://www.aecbytes.com"
            target="_blank"
            className="has-text-primary"
            rel="nofollow noreferrer"
          >
            AECbytes
          </Link>
          .
        </Typography>
        <Typography variant="body1">
          In this article, he will discuss the approach to conducting geodetic
          surveys on the premises of operating industrial enterprises using
          modern instruments and technologies.
        </Typography>
        <Typography variant="body1">
          Read the full text{' '}
          <Link
            href="https://www.aecbytes.com/tipsandtricks/2023/issue106-DigitalSurveying.html"
            target="_blank"
            className="has-text-primary"
            rel="nofollow noreferrer"
          >
            here
          </Link>
          .
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default DigitalSurveyingConstruction
